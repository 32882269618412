import Vue from 'vue'
import * as Utils from '@/utils/utils'
import {studyReport} from '@/modules/course/api/index'
import {checkLogin} from '@/utils/user';

let cacheKey = 'classTracking'
let memoryCache: any = {}

let getCache = () => {
  let cache = Utils.storage.getStorage(cacheKey) || {}
  return {
    val: cache.val || [],
    lastUpTime: cache.lastUpTime || ''
  }
}
let setCache = (arr: any, lastUpTime?: number) => {
  let cache = getCache()
  let val = cache.val
  if (arr.length > 0) {
    val = val.concat(arr)
  } else {
    val = []
  }
  Utils.storage.setStorage(cacheKey, {
    lastUpTime: lastUpTime || cache.lastUpTime,
    val
  })
}

class TrackObj {
  courseId: string
  classId: string
  type: string
  second: number
  startTime: number
  constructor(courseId: string, classId: string, type: string) {
    this.courseId = courseId
    this.classId = classId
    this.type = type
    this.second = 0
    this.startTime = +(new Date())
  }
  end(num: number) {
    let cur = +(new Date())
    let time = num ? Math.floor(num) : Math.floor((cur - this.startTime) / 1000)
    this.second = time
  }
  getData() {
    let cur = +(new Date())
    return {
      course_id: this.courseId,
      class_id: this.classId,
      subject: this.type,
      second_num: this.second,
      create_time: cur
    }
  }
  toCache() {
    let data = this.getData()
    if (this.second) {
      // 有效数据
      setCache([data])
    }
  }
}

if (!Vue.prototype.$isServer) {
  window.addEventListener('unload', e => {
    Object.keys(memoryCache).forEach(e => {
      memoryCache[e].end()
      memoryCache[e].toCache()
    })
  }, false)
}

export default {
  classEnter(params: any) {
    let courseId: string = params.courseId
    let classId: string = params.classId
    let trackObj = new TrackObj(courseId, classId, 'add_study_second')
    memoryCache[courseId + classId + 'ass'] = trackObj
    return trackObj
  },
  classLeave(params: any) {
    let courseId: string = params.courseId
    let classId: string = params.classId
    let trackObj = memoryCache[courseId + classId + 'ass']
    if (trackObj) {
      trackObj.end()
      trackObj.toCache()
      delete memoryCache[courseId + classId + 'ass']
    }
  },
  classMediaStart(params: any) {
    let courseId: string = params.courseId
    let classId: string = params.classId
    let trackObj = new TrackObj(courseId, classId, 'add_play_second')
    memoryCache[courseId + classId + 'apc'] = trackObj
    return trackObj
  },
  classMediaEnd(params: any) {
    let courseId: string = params.courseId
    let classId: string = params.classId
    let trackObj = memoryCache[courseId + classId + 'apc']
    if (trackObj) {
      trackObj.end()
      trackObj.toCache()
      delete memoryCache[courseId + classId + 'apc']
    }
  },
  classMediaMax(params: any) {
    let courseId: string = params.courseId
    let classId: string = params.classId
    let time: number = params.time
    let trackObj = new TrackObj(courseId, classId, 'new_max_play_second')
    if (trackObj) {
      trackObj.end(time)
      trackObj.toCache()
      delete memoryCache[courseId + classId + 'nmps']
    }
  },

  formatData(data: any) {
    let obj: any = {}
    let result: any = []
    // {
    //  course_id:{
    //    class_id: [{subject, value}],
    //    class_id: [{subject, value}]
    //  },
    //  course_id:{
    //    class_id: [{subject, value}],
    //    class_id: [{subject, value}]
    //  }
    // }
    data.forEach((e: any) => {
      let courseData = obj[e.course_id] || {}
      let classData = courseData[e.class_id] || []
      classData.push({
        subject: e.subject,
        create_time: e.create_time,
        value: e.second_num
      })
      courseData[e.class_id] = classData
      obj[e.course_id] = courseData
    })
    result = Object.keys(obj).map(courseId => {
      let classData = obj[courseId]
      let classes = Object.keys(classData).map(classId => {
        let statistics = classData[classId]
        let lastCreateTime = statistics[0].create_time
        let tempData: any = {}
        statistics.forEach((e: any) => {
          lastCreateTime = Math.max(e.create_time, lastCreateTime)
          if (tempData[e.subject]) {
            tempData[e.subject] += e.value
          } else {
            tempData[e.subject] = e.value
          }
          delete e.create_time
        })
        statistics = Object.keys(tempData).map((e: any) => {
          return {
            subject: e,
            value: tempData[e]
          }
        })
        lastCreateTime = new Date(lastCreateTime)

        return {
          class_id: classId,
          last_studied_time: lastCreateTime.toISOString(),
          statistics
        }
      })
      return {
        course_id: courseId,
        classes
      }
    })
    return result
  },
  async checkUpload() {
    let cache = getCache()
    let date = new Date()
    let cur = Math.floor(date.getTime() / 1000)
    let canUp = true
    if (!checkLogin()) {
      return Promise.resolve()
    }
    try {
      if (cache.val.length > 0 && canUp) {
        let data = this.formatData(cache.val)
        for (let i = 0; i < data.length; i++) {
          let v = data[i]
          let params = {
            course_id: v.course_id,
            classes: v.classes
          }
          await studyReport(params)
        }
        setCache([], cur)
      }
    } catch (e) {
      setCache([], cur)
    }
  }
}
