import { Vue, Component, Prop } from 'vue-property-decorator'
import { CourseInfo } from '@/modules/course/models'
import LBlockWrap from '@/modules/course/components/l-block-wrap.vue'
import RBlockWrap from '@/modules/course/components/r-block-wrap.vue'

@Component({
  components: {
    LBlockWrap,
    RBlockWrap
  }
})
export default class CourseDetailComponentMixins extends Vue {
  @Prop() courseDetail!: CourseInfo
}
