














import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import mixins from './mixins'

  @Component({
  })
export default class Students extends Mixins(mixins) {
  get show() {
    return this.courseDetail.students.total > 0
  }
  get students() {
    return this.courseDetail.students.data
  }
}
