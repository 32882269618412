

















import { Component, Vue, Watch, Mixins } from 'vue-property-decorator'
import GHlsVideo from '@/modules/common/components/g-hls-video.vue'
import CourseClassMixins from './mixins'
import {ClassTrackingV2} from './class-tracking-v2';

  @Component({
    components: {
      GHlsVideo
    }
  })
export default class CourseVideoClass extends Mixins(CourseClassMixins) {
  videoCurrent: number = 0
  videoDuration: number = 0
  videoSpeed: any = 1
  trackObj = new ClassTrackingV2({
    type: 'course', // 类型 课程/训练营
    courseId: this.courseDetail.content_id, // 课程id
    classId: this.classDetail.id, // 课时id
    contentType: this.classDetail.content_type, // 内容类型
    contentId: this.classDetail.id, // 内容id
    mediaType: 'video', // 媒体类型，训练营要区分音频和视频 audio video
    disabled: false
  })

  get playData() {
    if (this.previewConfig) {
      return {
        fileId: this.previewConfig.preview_video_id,
        token: this.previewConfig.preview_token
      }
    } else {
      return {
        fileId: this.classDetail.content.file_id,
        token: this.classDetail.content.token
      }
    }
  }
  get coverPath() {
    if (this.previewConfig) {
      return this.previewConfig.preview_video_cover || ''
    } else {
      let content =  this.classDetail.content
      return content.patch || content.cover_url
    }
  }

  created() {
    this.trackObj.pageEnter()
  }

  beforeDestroy() {
    this.trackObj.pageLeave()
  }

  videoEvent(event: any) {
    let eventName = event.type
    if (eventName === 'playing') {
      this.trackObj.mediaStart({
        mediaPlayedSecond: this.videoCurrent,
        mediaTotalSecond: this.videoDuration
      })
    } else if (eventName === 'pause') {
      this.trackObj.mediaStop({
        mediaPlayedSecond: this.videoCurrent
      })
    } else if (eventName === 'timeupdate') {
      this.videoCurrent = (this.$refs.gVideo as any).qcVideo.currentTime()
      this.videoDuration = (this.$refs.gVideo as any).qcVideo.duration()
      this.trackObj.mediaPlaying({
        mediaPlayedSecond: this.videoCurrent
      })
    } else if (eventName === 'error') {
      this.trackObj.mediaStop({
        mediaPlayedSecond: this.videoCurrent
      })
    } else if (eventName === 'ended') {
      this.playEnd()
    }
  }
  beforeLeave() {
    this.trackObj.pageLeave()
  }
  playEnd() {
    // 播放完 videoCurrent 会归0这里取全时长
    // this.beforeLeave()
    this.trackObj.mediaFinished_({
      mediaPlayedSecond: this.classDetail.content.duration_sec,
      mediaFinished: true
    })
    if (this.courseDetail._lockMode) {
      // 解锁模式下 解锁下一个课时
      this.$emit('unlockNext')
    }
  }
  speedChangedHandler(speed: any) {
    if (this.$refs.gVideo) {
      ;(this.$refs.gVideo as GHlsVideo).changeSpeed(speed);
      this.videoSpeed = speed
    }
  }
}
