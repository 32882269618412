import { render, staticRenderFns } from "./ueditor-cut.vue?vue&type=template&id=2712c37a&scoped=true&"
import script from "./ueditor-cut.vue?vue&type=script&lang=ts&"
export * from "./ueditor-cut.vue?vue&type=script&lang=ts&"
import style0 from "./ueditor-cut.vue?vue&type=style&index=0&id=2712c37a&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2712c37a",
  null
  
)

export default component.exports