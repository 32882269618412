














































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

import ArticlePage from './article.vue'
import AudioPage from './audio.vue'
import VideoPage from './video.vue'
import ContentLayout from '@/modules/course/components/content-layout.vue'
import ExtLearning from '@/modules/course/pages/course-detail/components/ext-learning.vue'
import Students from '@/modules/course/pages/course-detail/components/students.vue'
import ClassList from './components/class-list/index.vue'
import ClassCourseInfo from './components/class-course-info.vue'
import ClassTracking from './class-tracking'

import {
  getChapterList,
  getCourseDetail,
  getClassCourseDetail,
  getUnlockList,
  getViewCount
} from '@/modules/course/api'
import { CharpterClass, ClassInfo, CourseInfo } from '@/modules/course/models'
import {checkLogin, getSign} from '@/utils/user';

  @Component({
    components: {
      ArticlePage,
      AudioPage,
      VideoPage,
      ExtLearning,
      Students,
      ClassList,
      ClassCourseInfo,
      ContentLayout
    }
  })
export default class ClassIndex extends Vue {
    isLoading: boolean = true
    errorObj: any = null

    courseId: string = ''
    cId: string = ''
    classDetail: ClassInfo = {}
    courseDetail: CourseInfo = {}
    charpterList: Array<CharpterClass> = []
    unlockData: any = {}

    get pageType() {
      let type = this.classDetail.content_type
      console.log(this.classDetail,'this.classDetail');
      
      console.log(type,'type');
      return ['article', 'audio', 'video'].indexOf(type) !== -1 ? `${type}-page` : ''
    }

    created() {
    }
    mounted() {
      // ClassTracking.checkUpload().then(() => {
      this.pageInit()
      // })
      // if (!Vue.prototype.$isServer) {
      //   window.addEventListener('unload', this.unloadHandler, false)
      // }
    }

    beforeDestroy() {
      this.classTrack()
    }

    beforeRouteUpdate(to: any, from: any, next: any) {
      this.classTrack()
      // ClassTracking.checkUpload().then(() => {
      next()
      this.pageInit()
      // }, () => {
      //   next()
      //   this.pageInit()
      // })
    }

    // beforeRouteLeave(to: any, from: any, next: any) {
    //   this.unloadHandler()
    //   window.removeEventListener('unload', this.unloadHandler, false)
    //   next()
    // }

    // unloadHandler() {
    //   this.classTrack()
    // }
    classTrack() {
      // let courseId = this.courseDetail.content_id
      // let classId = this.classDetail.id
      // ClassTracking.classMediaEnd({courseId, classId})
      // ClassTracking.classLeave({courseId, classId})

      let clsPage: any = this.$refs.curClassPage
      if (clsPage && clsPage.beforeLeave) {
        clsPage.beforeLeave()
      }
    }

    getCharpterList() {
      return getChapterList({
        id: this.courseId,
        count: 999
      }).then((e: any) => {
        if (!e.error) {
          console.log(e, '最外层id');
          
          let charpterList = e.data
          charpterList.map((e: CharpterClass, i: number) => {
            e._index = i + 1
          })
          this.charpterList = charpterList
        } else {
          this.$message('目录:' + e.errorObj.title)
        }
      })
    }
    getCourse () {
      let id = this.courseId
      // 课程详情
      return getCourseDetail(id).then(res => {
        if (!res.error) {
          console.log(res, '只有课时id');
          
          this.courseDetail = res
        } else {
          this.errorObj = {
            html: res.message || '课程详情服务繁忙'
          }
          this.isLoading = false
        }
      })
    }
    getClassData() {
      let { courseId, cId } = this
      console.log(courseId, 'courseId');
      console.log(courseId, 'courseId');
      return getClassCourseDetail({
        course_id: courseId,
        class_id: cId
      }).then(e => {
        console.log(e,'有cid的内容');
        
        if (!e.error) {
          this.afterData(e)
        } else if (e.error === 'no-pay') {
          this.afterData()
        } else {
          this.errorObj = {
            html: e.message || '课时详情服务繁忙'
          }
          this.isLoading = false
        }
      }, e => {
        this.errorObj = {
          html: e.message || '课时详情服务繁忙'
        }
        this.isLoading = false
      })
    }

    pageInit() {
      this.courseDetail = {}
      this.classDetail = {}
      this.courseId = this.$route.params.courseId
      this.cId = this.$route.params.cId
      this.isLoading = true
      return Promise.all([this.getCharpterList(), this.getCourse()]).then(e => {
        return this.getClassData()
      })
    }
    afterData(classDetail?: ClassInfo) {
      let courseDetail = this.courseDetail
      let isFree = classDetail ? classDetail.is_free : false
      let sub = courseDetail.is_subscribe
      let lockMode = this.courseDetail.study_mode === 'sequentially' && sub

      let p: any
      if (lockMode && sub) {
        p = this.getUnlockData()
      } else {
        p = Promise.resolve()
      }
      p.then(() => {
        this.$set(this.courseDetail, '_lockMode', lockMode)

        if (!classDetail || (!isFree && !sub)) {
          // 未订阅 又不是试看 去详情
          this.$router.replace({
            name: 'course',
            params: {
              id: this.courseId
            }
          })
          return
        } else if (classDetail.content_type === 'exam') {
          this.$showH5qrCode({
            name: 'CourseClass',
            params: {
              cId: this.cId,
              courseId: this.courseId
            }
          })
          return
        } else if (classDetail.content_type === 'live') {
          this.$showH5qrCode({
            name: 'CourseStudio',
            params: {
              id: this.cId,
              courseId: this.courseId
            },
            query: {
              classContentId: classDetail.content.content_id
            }
          })
          return
        } else if (lockMode && sub) {
          let targetIds = this.unlockData.target_ids || []
          targetIds = targetIds.map((e: any) => parseInt(e.split(':')[1]))

          let unlocked = targetIds.indexOf(classDetail.id) !== -1
          if (!unlocked) {
            this.$router.replace({
              name: 'course',
              params: {
                id: this.courseId
              }
            })
            this.$message('抱歉，该课时还未解锁，请先完成前置课时')
            return
            // classDetail._classLocked = true
          }
        }

        this.classDetail = classDetail

        getViewCount({id: this.classDetail.id})
        // 重置标题
        // utils.windowTitle(classDetail.title)

        this.isLoading = false
      })
    }

    getUnlockData() {
      let logined = checkLogin()
      if (!logined) return
      let userId = getSign().id
      let {content_id: contentId} = this.courseDetail
      return getUnlockList({userId, contentId}).then(e => {
        this.unlockData = e
      })
    }

    updateUnlockData(e: any) {
      console.log(e, 'kankanzheshi1');
      
      this.unlockData = e
    }

    unlockNext() {
      if (this.courseDetail.is_subscribe) {
        return this.$refs.catalog && (this.$refs.catalog as any).unlockNext()
      }
    }
}
