










import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import mixins from './mixins'
import {
  getCourseList
} from '@/modules/course/api'
import { CourseInfo } from '@/modules/course/models'

  @Component({
  })
export default class ExtLearning extends Mixins(mixins) {
    ajaxData: any = {}

    get list() {
      let list = this.ajaxData.data || []
      return list.slice(0, 2)
    }

    mounted() {
      this.getData()
    }

    getData() {
      return getCourseList({
        order: 'subscribe',
        page: 1,
        count: 5
      }).then(e => {
        this.ajaxData = e
      })
    }
    toDetail(v: CourseInfo) {
      this.$router.push({
        name: 'course',
        params: {
          id: v.content_id
        }
      })
    }
}
