












import { Component, Vue, Prop } from 'vue-property-decorator'

  @Component({
    props: {
      blockName: {
        type: String,
        default: '未知'
      }
    }
  })
export default class VueComponent extends Vue {
}
