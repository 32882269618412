import { Vue, Component, Prop } from 'vue-property-decorator'
import CourseClassHeader from './components/class-header.vue'
import CourseClassMaterial from './components/class-material.vue'
import { CharpterClass, ClassInfo, CourseInfo } from '@/modules/course/models'
import SpeedBtn from '@/modules/common/components/speed-btn.vue'

@Component({
  components: {
    CourseClassHeader,
    SpeedBtn,
    CourseClassMaterial
  }
})
export default class CourseClassMixins extends Vue {
  @Prop() classDetail!: ClassInfo
  @Prop() courseDetail!: CourseInfo
  @Prop() charpterList!: Array<CharpterClass>

  get showTryTips() {
    return this.classDetail.is_free && !this.courseDetail.is_subscribe
  }
  get previewConfig() {
    let {content, content_type: contentType, preview_config: data} = this.classDetail
    if (data && data.preview_type === 'total') {
      if (contentType === 'video') {
        data.preview_video_id = content.file_id
        data.preview_token = content.token
        data.preview_video_cover = content.patch
      } else if (contentType === 'audio') {
        data.preview_audio_path = content.url
      }
    }
    return this.showTryTips && data ? data : null
  }

  toDetail() {
    this.$router.push({
      name: 'course',
      params: {
        id: this.courseDetail.content_id
      }
    })
  }
}
