






















import { Component, Vue, Prop } from 'vue-property-decorator'
import {ClassInfo, CourseInfo} from '@/modules/course/models'
import {accMul} from '@/utils/utils'

  @Component({
  })
export default class CourseClassHeader extends Vue {
    @Prop() classDetail!: ClassInfo
    @Prop() courseDetail!: CourseInfo
    @Prop({ default: true }) border!: boolean

    get contentTypeSn() {
      let map: any = {
        'article': '图文',
        'audio': '音频',
        'video': '视频'
      }
      return map[this.classDetail.content_type]
    }

    get processingPrecent() {
      let studyProgress = this.classDetail.study_progress || 0
      let precent = accMul(studyProgress, 100)
      return precent
    }
}
