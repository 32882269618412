<template>
  <div class="audio-page">
    <course-class-header :border="false" :class-detail="classDetail" :course-detail="courseDetail"></course-class-header>
    <template v-if="previewConfig">
      <ueditor-cut :precent="previewConfig.preview_type == 'total' ? 1 : parseFloat(previewConfig.preview_percent)" :content="classDetail.content.content">
        <a href="javascript:void(0)" class="more-btn" @click="toDetail()">购买查看完整内容</a>
      </ueditor-cut>
    </template>
    <ueditor-cut @ready="articleCtnReady" ref="articleCtn" :content="classDetail.content.content" v-else></ueditor-cut>
    <course-class-material class="material" :materials="classDetail.materials" v-if="courseDetail.is_subscribe"></course-class-material>
  </div>
</template>
<script>
import { Component, Vue, Watch, Mixins } from 'vue-property-decorator'
import CourseClassMixins from './mixins'
import UeditorCut from '@/modules/common/components/ueditor-cut.vue'
import ClassTracking from './class-tracking'
import ArticleTaskUpMixins from './article-task-up-mixins'
import { ClassTrackingV2 } from './class-tracking-v2'

  @Component({
    components: {
      UeditorCut
    }
  })
export default class CourseArticleClass extends Mixins(CourseClassMixins, ArticleTaskUpMixins) {
  trackObj = new ClassTrackingV2({
    type: 'course', // 类型 课程/训练营
    courseId: this.courseDetail.content_id, // 课程id
    classId: this.classDetail.id, // 课时id
    contentType: this.classDetail.content_type, // 内容类型
    contentId: this.classDetail.id, // 内容id
    mediaType: '', // 媒体类型，训练营要区分音频和视频 audio video
    disabled: false
  })

  beforeDestroy() {
    this.trackObj.pageLeave()
  }

  created() {
    // let courseId = this.courseDetail.content_id
    // let classId = this.classDetail.id
    // ClassTracking.classEnter({courseId, classId})
    // let courseId = this.courseDetail.content_id
    // let classId = this.classDetail.id
    this.trackObj.pageEnter()
  }

  mounted() {
    if (this.courseDetail._lockMode) {
      this.$emit('unlockNext')
    }
  }

  beforeLeave() {
    this.trackObj.pageLeave()
  }

  articleCtnReady() {
    this.checkBottomed()
    this.setArticleTaskTimer(this.classDetail.id)
  }
}
</script>
<style lang="scss" scoped rel="stylesheet/scss">
  @import '@/style/theme.scss';
  .material{
    margin-top:40px;
  }
  .ext-bar-label{
    font-size:14px;
  }
  .more-btn{
    display:block;
    width:143px;
    height:35px;
    line-height:33px;
    margin:10px auto;
    background:rgba(255,255,255,1);
    text-align:center;
    border:1px solid rgba(0,149,255,1);
    border-color:$color-theme;
    color:$color-theme;
    border-radius:2px;
    font-size:14px;
    transition: all .3s;
    &:hover{
      background:$color-theme;
      color:#fff;
    }
  }
</style>
