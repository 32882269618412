














import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ueditorDisplay from '@/modules/common/components/ueditor-display/index.vue'
@Component({
  components: {
    ueditorDisplay
  }
})
export default class UeditorCut extends Vue {
  @Prop()
  content?: string
  @Prop()
  precent?: number
  @Prop({default: 750})
  height?: number

  contentCutStyle: any = {}
  contentCutBtn: boolean = false

  show() {
    this.checkCut(false)
    this.contentCutBtn = false
  }
  hide() {
    this.checkCut()
  }
  checkCut(cut = true) {
    let precent = (this as any).precent * 100
    let height = (this.$refs.contentCut as any).offsetHeight
    let cutHeight = height
    // 优先用 precent
    if (precent) {
      let h = height * precent / 100
      let minH = 10 // 内空过少时处理
      this.contentCutBtn = precent !== 100
      cutHeight = h < minH ? `${minH}px` : `${h}px`
    } else {
      // 50 为按钮高
      let maxHeight = (this as any).height + 50
      this.contentCutBtn = height > maxHeight
      cutHeight = this.contentCutBtn ? `${maxHeight}px` : `auto`
    }
    this.contentCutStyle = {
      height: cut ? cutHeight : 'auto',
      opacity: 1,
      position: 'relative'
    }
  }
  editorImgsReady() {
    this.contentCutStyle = {}
    this.$nextTick(() => {
      this.checkCut()
      this.$nextTick(() => {
        this.$emit('ready')
      })
    })
  }
}
