import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({
})
export default class ArticleTaskUpMixins extends Vue {
  bottomed: boolean = false
  enterArticleId: string = ''
  enterTimer: any = null
  enterTime: number = 0
  enterTimeUp: number = 5 + Math.floor(Math.random() * 6)

  mounted() {
    if (!Vue.prototype.$isServer) {
      window.addEventListener('scroll', this.checkBottomed)
    }
  }

  setArticleTaskTimer(articleId: string) {
    this.enterArticleId = articleId
    clearInterval(this.enterTimer)
    this.enterTimer = setInterval(() => {
      this.enterTime++
      this.checkArticleTaskUp()
    }, 1000)
  }
  checkArticleTaskUp() {
    if (
      this.enterTime >= this.enterTimeUp &&
      this.bottomed
    ) {
      clearInterval(this.enterTimer)
    }
  }
  checkBottomed() {
    let $elm = this.$refs.articleCtn && (this.$refs.articleCtn as any).$el
    if ($elm) {
      let screenH = window.screen.availHeight
      let rect = $elm.getBoundingClientRect()
      if (rect.bottom < screenH) {
        this.bottomed = true
      }
    }
  }
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkBottomed)
    clearInterval(this.enterTimer)
  }
  @Watch('$route')
  on$routeChanged() {
    this.bottomed = false
    this.enterTime = 0
  }
}
