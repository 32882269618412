
























import { Component, Vue, Watch, Mixins } from 'vue-property-decorator'
import GAudio from '@/modules/common/components/g-audio.vue'
import CourseClassMixins from './mixins'
import {ClassTrackingV2} from './class-tracking-v2';

  @Component({
    components: {
      GAudio
    }
  })
export default class CourseAudioClass extends Mixins(CourseClassMixins) {
  audioPlaying: boolean = false
  audioCurrent: number = 0
  audioDuration: number = 0
  audioSpeed: number = 1
  trackObj = new ClassTrackingV2({
    type: 'course', // 类型 课程/训练营
    courseId: this.courseDetail.content_id, // 课程id
    classId: this.classDetail.id, // 课时id
    contentType: this.classDetail.content_type, // 内容类型
    contentId: this.classDetail.id, // 内容id
    mediaType: 'audio', // 媒体类型，训练营要区分音频和视频 audio video
    disabled: false
  })

  @Watch('audioPlaying')
  onAudioPlayingChanged() {
    if (this.audioPlaying) {
      // 开始播放
      this.trackObj.mediaStart({
        mediaPlayedSecond: this.audioCurrent,
        mediaTotalSecond: this.audioDuration
      })
    } else {
      // 暂停
      this.trackObj.mediaStop({
        mediaPlayedSecond: this.audioCurrent
      })
    }
  }

  created() {
    this.trackObj.pageEnter()
  }

  beforeDestroy() {
    this.trackObj.pageLeave()
  }

  get audioUrl() {
    if (this.previewConfig && this.previewConfig.preview_type !== 'total') {
      return this.previewConfig.preview_audio_url || ''
    } else {
      return this.classDetail.content.url
    }
  }

  audioValueChange(params:any) {
    let name: string = params.name
    let value: any = params.value
    switch (name) {
      case 'audioCurrent':
      case 'audioDuration':
        this[name] = value
        if (this.audioPlaying) {
          this.trackObj.mediaPlaying({
            mediaPlayedSecond: this.audioCurrent
          })
        }
        break
      case 'playing':
        this['audioPlaying'] = value
        break
    }
  }
  beforeLeave() {
    this.trackObj.pageLeave()
  }
  playEnd() {
    // 播放完 audioCurrent 会归0这里取全时长
    // this.beforeLeave()
    this.trackObj.mediaFinished_({
      mediaPlayedSecond: this.classDetail.content.duration_sec,
      mediaFinished: true
    })
    if (this.courseDetail._lockMode) {
      // 解锁模式下 解锁下一个课时
      this.$emit('unlockNext')
    }
  }
  audioEvent(e: any) {
    if (e.type === 'error') {
      this.trackObj.mediaStop({
        mediaPlayedSecond: this.audioCurrent
      })
    }
  }
}
