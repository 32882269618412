import {studyReport} from '@/modules/course/api/index'
import uuidv4 from 'uuid/v4'

// api 文档
// 训练营 https://www.tapd.cn/20930771/markdown_wikis/#1120930771001001285
// 课程   http://yapi.hogecloud.com/project/11/interface/api/1688

export class ClassTrackingV2 {
  disabled: boolean
  type: string
  courseId: string
  contentType: string
  classId: string
  contentId: string
  mediaType: string
  timer: any
  startTime: any
  mediaPlayedSecond: any
  mediaTotalSecond: any
  accumulatePlayedSecond: any
  mediaStartTime: any
  mediaStatus: any
  timeout: any
  stayTime: any
  mediaFinished: any
  sid: any

  constructor({
    type, // 类型 课程/训练营
    courseId, // 课程id
    classId, // 课时id
    contentType, // 内容类型
    contentId, // 内容id
    mediaType, // 媒体类型，训练营要区分音频和视频 audio video
    disabled
  }: any) {
    this.disabled = disabled || false // false 不发上报
    this.type = type
    this.courseId = courseId || ''
    this.contentType = contentType || ''
    this.classId = classId || ''
    this.contentId = contentId || ''
    this.mediaType = mediaType || ''
    this.timer = null
    this.startTime = null // 进入页面时间
    this.mediaPlayedSecond = 0 // 当前播放进度时长
    this.mediaTotalSecond = 0 // 接口返回的总时长
    this.accumulatePlayedSecond = 0 // 播放持续时间记录
    this.mediaStartTime = 0 // 媒体触发播放事件的时间，用来计算持续播放的时间
    this.mediaStatus = 'stop' // 媒体播放状态
    this.timeout = 30
    this.stayTime = 0 // 页面停留时长
    this.mediaFinished = false // 媒体是否播放结束
    this.sid = uuidv4().replace(/-/g, '')
  }
  // 方法 map
  // 类型: {
  //   api,
  //   构造请求数据,
  //   参数事件
  // }
  get _map(): any {
    return {
      course: {
        api(params: any) {
          // params.classes[0].statistics.forEach((e: any) => {
          //   console.info(e)
          // })
          // console.warn('---')
          return studyReport(params)
        },
        paramsMethod({subjects, _this}: any) {
          let items: any = []
          subjects.forEach((e: any) => {
            let sItem = {
              subject: (this.events as any)[e.subjectType],
              value: e.value
            }
            items.push(sItem)
          })

          return {
            course_id: _this.courseId,
            classes: [{
              sid: _this.sid,
              class_id: _this.classId,
              // last_studied_time: this.startTime.toISOString(),
              statistics: items
            }]
          }
        },
        events: {
          stayTime: 'add_study_second', // 页面停留时长
          accumulatePlayedSecond: 'add_play_second', // 累计播放时长
          mediaPlayedSecond: 'new_max_play_second' // 当前播放进度
        }
      }
      // camp: {
      //   api(params) {
      //     params.forEach(data => {
      //       track.send({
      //         ...data,
      //         logLib: 'study_stats'
      //       })
      //     })
      //   },
      //   paramsMethod({subjects, _this}) {
      //     let items = []
      //     let sign = userinfo.getCache() || {}
      //     subjects.forEach(e => {
      //       let trackType = this.events[e.subjectType]
      //       let obj = {
      //         '__topic__': utils.getShopId(),
      //         member_id: sign.id,
      //         content_type: this.contentType,
      //         content_id: this.contentId,
      //         metric: trackType
      //       }
      //
      //       switch (trackType) {
      //         case 'time_on_content':
      //           obj.value = _this.stayTime
      //           break
      //         case 'time_played':
      //           obj.value = _this.mediaPlayedSecond
      //           break
      //         case 'playback_progress':
      //           obj.max_value = _this.mediaTotalSecond
      //           obj.finished = _this.mediaFinished ? 1 : 0
      //           obj.value = _this.mediaFinished ? _this.mediaTotalSecond : _this.mediaPlayedSecond
      //           break
      //       }
      //
      //       items.push(obj)
      //     })
      //
      //     return items
      //   },
      //   events: {
      //     stayTime: 'time_on_content',
      //     accumulatePlayedSecond: 'time_played',
      //     mediaPlayedSecond: 'playback_progress'
      //   }
      // }
    }
  }

  // 创建计时器，定时上报数据
  _start() {
    console.log('_start')

    this.startTime = new Date()
    this._checkSend()

    if (!this.timer) {
      this.timer = setInterval(() => {
        this._checkSend()
      }, this.timeout * 1000)
    }
  }

  // 音视频事件
  // event : start stop playing
  // mediaPlayedSecond 当地进度 s
  // mediaTotalSecond 接口返的音视频总时常长
  _event({
    event,
    mediaPlayedSecond,
    mediaTotalSecond
  }: any) {
    if (mediaPlayedSecond) {
      this.mediaPlayedSecond = mediaPlayedSecond
    }
    if (mediaTotalSecond) {
      this.mediaTotalSecond = mediaTotalSecond
    }
    if (event === 'start') {
      this.mediaStatus = event
      this.mediaStartTime = +(new Date())
      this._checkSend()
    } else if (event === 'stop') {
      this._checkSend()
      this.mediaStartTime = 0
      this.mediaStatus = event
    } else if (event === 'playing') {
      this.mediaStatus = event
      // if (!this.mediaStartTime) {
      //   this.mediaStartTime = +(new Date())
      // }
    } else if (event === 'finished') {
      this.mediaFinished = true
      this._checkSend()
      this.mediaStatus = event
      this.mediaStartTime = 0
    }
  }

  // 暂停或结束计时，上报数据
  _end() {
    console.log('end')
    this._checkSend()
    clearInterval(this.timer)
    this.timer = null
  }

  // 上报判断
  _send() {
    let eventParams: any = {
      stayTime: this.stayTime
    }
    if (this.mediaType && this.mediaStatus !== 'stop' && this.mediaStatus !== 'finished') {
      eventParams.accumulatePlayedSecond = parseInt(this.accumulatePlayedSecond)
      eventParams.mediaPlayedSecond = parseInt(this.mediaPlayedSecond)
    }
    let _this = this
    let subjects = this._getStatisticsParams(eventParams)
    let mapItem = this._map[this.type]
    return mapItem.api(mapItem.paramsMethod({
      subjects,
      _this
    }))
  }

  _checkEnabled() {
    return !this.disabled && +this.startTime > 0
  }

  _checkSend() {
    this._caculateStayTime()
    this._caculateAccumulatePlayedSecond()
    this._checkEnabled() && this._send()
  }

  // 发送的参数
  _getStatisticsParams({stayTime, accumulatePlayedSecond, mediaPlayedSecond}: any) {
    let subjects = [{
      subjectType: 'stayTime',
      value: stayTime
    }]

    if (accumulatePlayedSecond) {
      subjects.push({
        subjectType: 'accumulatePlayedSecond',
        value: accumulatePlayedSecond
      })
    }
    if (mediaPlayedSecond) {
      subjects.push({
        subjectType: 'mediaPlayedSecond',
        value: +(mediaPlayedSecond).toFixed(3)
      })
    }
    return subjects
  }

  // 停留时间
  _caculateStayTime() {
    let now = +(new Date())
    this.stayTime = Math.round((now - +this.startTime) / 1000)
    this.startTime = new Date()
    return this.stayTime
  }

  // 播放持续时间
  _caculateAccumulatePlayedSecond() {
    if (!this.mediaStartTime) return
    let now = +(new Date())
    let cTime = Math.round((now - this.mediaStartTime) / 1000)
    this.accumulatePlayedSecond = cTime
    this.mediaStartTime = now
  }

  // 页面进入
  pageEnter() {
    this._start()
  }

  // 页面退出
  pageLeave() {
    this._end()
  }

  // 媒体开始播放
  mediaStart({mediaPlayedSecond, mediaTotalSecond}: any) {
    this.mediaPlayedSecond = mediaPlayedSecond
    this._event({
      mediaPlayedSecond,
      mediaTotalSecond,
      event: 'start'
    })
  }

  mediaPlaying({mediaPlayedSecond}: any) {
    this._event({
      mediaPlayedSecond,
      event: 'playing'
    })
  }

  mediaStop({mediaPlayedSecond}: any) {
    this._event({
      mediaPlayedSecond,
      event: 'stop'
    })
  }

  mediaFinished_({mediaPlayedSecond}: any) {
    this._event({
      mediaPlayedSecond,
      event: 'finished'
    })
  }

  setDisabled(disabled: boolean) {
    this.disabled = disabled || false
  }
}
