




























import { Component, Vue, Prop } from 'vue-property-decorator'
import {Popover, Slider} from 'element-ui'

@Component({
  components: {
    [Popover.name]: Popover,
    [Slider.name]: Slider
  },
  filters: {
    formatNum(val: number) {
      let str = val.toString()
      if (str.length < 2) {
        str = val.toFixed(1)
      }
      return str
    }
  }
})
export default class SpeedBtn extends Vue {
  mediaSliderMarks: any = {
    '0.5': '0.5',
    '0.75': '0.75',
    '1': '1.0',
    '1.25': '1.25',
    '1.5': '1.5',
    '1.75': '1.75',
    '2': '2.0'
  }

  @Prop()
  mediaSpeed!: number
}
