









import { Component, Vue, Prop } from 'vue-property-decorator'
import { CourseInfo } from '@/modules/course/models'

  @Component({
  })
export default class ClassCourseInfo extends Vue {
    @Prop() courseDetail!: CourseInfo
    toDetail() {
      if(this.courseDetail.type == 'column') {
        this.$router.push({
          name: 'columnDetail',
          params: {
            id: this.courseDetail.column_id
          }
        })
      } else {
        this.$router.push({
          name: 'course',
          params: {
            id: this.courseDetail.content_id
          }
        })

      }
      
    }
}
