



































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import {getPlayInfo} from '@/modules/common/api'
import GVideoCopyrightMask from '@/modules/common/components/g-video-copyright.vue'

let id = 0
  @Component({
    components: {
      GVideoCopyrightMask
    }
  })
export default class GHlsVideo extends Vue {
    @Prop({
      type: Boolean,
      default: true
    })
    defautErr!: boolean
    @Prop({
      type: Boolean,
      default: false
    })
    live!: boolean
    @Prop({
      type: String,
      default: 'https://sina-image.duanshu.com/2019/09/23/13/4bej13g0645928574d/common/content/1569218110541_737215.jpg?imageMogr2/auto-orient/thumbnail/640x400/'
    })
    coverImg!: string
    @Prop({
      type: Object,
      default: () => {
        return {
          fileId: '',
          token: ''
        }
      }
    })
    playData!: any

    playerHtml: string = ''
    errorText: string = ''
    playing: boolean = false
    copyrigntDoing: boolean = false
    qcVideo: any = null
    id: string = 'J_video' + id
    isTCPlayer: boolean = false // 当前播放器是 isTCPlayer

    created() {
      id++
    }
    mounted() {
      let {fileId, token} = this.playData
      this.playerHtml = (this.$refs.videoBox as any).innerHTML
      if (fileId || token) {
        this.initTcPlayer(this.playData)
      }
    }
    beforeDestroy () {
      this.destroyPlayer()
    }

    // 初始化
    initTcPlayer(playData: any, fileIdInfo?: any) {

      if (this.qcVideo) {
        this.destroyPlayer()
      }
      let p: any = Promise.resolve(fileIdInfo)
      if (!fileIdInfo) {
        p = this._getFileIdInfo(playData)
      }

      ;(this.$refs.videoBox as any).innerHTML = this.playerHtml

      // 查完接口才知道 用哪个播放器
      return p.then((fileIdInfo: any) => {
        this.isTCPlayer = fileIdInfo.encrypt
        // 是否加密视频
        if (this.isTCPlayer) {
          return this.getTCPlayer(playData, fileIdInfo)
        } else {
          return this.getTcPlayer(fileIdInfo)
        }
      }).then((player: any) => {
        this.qcVideo = player
      }).catch(() => {})
    }
    // 播放
    playVideo() {
      if (!this.qcVideo) {
        return
      }
      this.qcVideo.play()
    }
    // 销毁
    destroyPlayer() {
      if (!this.qcVideo) {
        return
      }
      if (this.isTCPlayer) {
        this.qcVideo.dispose()
      } else {
        this.qcVideo.destroy()
      }
      ;(this.$refs.videoBox as any).innerHTML = ''
      this.qcVideo = null
    }
    reloadPlayer(playData: any) {
      this.errorText = ''
      return this._getFileIdInfo(playData).then(fileIdInfo => {
        if (!this.qcVideo || this.isTCPlayer !== fileIdInfo.encrypt) {
          // 没初始化过 或 播放器不同于初始化的
          return this.initTcPlayer(playData, fileIdInfo)
        } else {
          // 相同播放器
          return new Promise((resolve, reject) => {
            if (this.isTCPlayer) {
              let config: any = this._getTCPlayerConfig(playData, fileIdInfo)
              this.qcVideo.loadVideoByID(config)
            } else {
              let {video_path: videoPath} = playData
              this.qcVideo.load(videoPath)
            }
            resolve()
          })
        }
      }, e => {})
    }
    // 非加密播放器
    getTcPlayer (fileIdInfo: any) {
      const self = this
      let {url: videoPath} = fileIdInfo
      let videoParam: any = {
        width: '100%',
        height: '100%',
        m3u8: videoPath,
        live: this.live,
        wording: {
          '2': '网络错误，请检查网络配置或者播放链接是否正确',
          '13': '直播已结束，请稍后再来',
          '2032': '请求视频失败，请检查网络',
          '2048': '请求m3u8文件失败，可能是网络错误或者跨域问题'
        },
        listener(msg: any) {
          self.$emit('listenerEvent', msg)
          if (msg.type === 'play') {
            self._playHandler()
          } else if (msg.type === 'pause') {
            self._pauseHandler()
          } else if (msg.type === 'error') {
            self._errorHandler(msg)
          }
        }
      }
      let player = new window.TcPlayer(this.id, videoParam)
      this._readyHandler()
      return player
    }
    // 加密播放器
    getTCPlayer (playData: any, fileIdInfo: any) {
      let config = this._getTCPlayerConfig(playData, fileIdInfo)
      let videoParam: any = {
        ...config,
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        controlBar: {
          playbackRateMenuButton: true
        }
      }
      let player = new window.TCPlayer(`${this.id}_video`, videoParam)
      player.on('load', (e: any) => {
        this.$emit('listenerEvent', e)
      })
      player.on('play', (e: any) => {
        this.$emit('listenerEvent', e)
        this._playHandler()
      })
      player.on('playing', (e: any) => {
        this.$emit('listenerEvent', e)
      })
      player.on('timeupdate', (e: any) => {
        this.$emit('listenerEvent', e)
      })
      player.on('ended', (e: any) => {
        this.$emit('listenerEvent', e)
      })
      player.on('seeking', (e: any) => {
        this.$emit('listenerEvent', e)
      })
      player.on('pause', (e: any) => {
        this.$emit('listenerEvent', e)
        this._pauseHandler()
      })
      player.on('error', (e: any) => {
        this.$emit('listenerEvent', e)
        this._errorHandler(e)
      })
      player.on('ready', (e: any) => {
        this.$emit('listenerEvent', e)
        this._readyHandler()
      })
      return player
    }

    _playHandler() {
      this.playing = true
      this.copyrigntDoing = true
    }
    _pauseHandler() {
      this.copyrigntDoing = false
    }
    _errorHandler(msg: any) {
      this.error(msg)
    }
    _readyHandler() {
      let id = this.id
      let video = document.getElementById(`${id}_video`)
      if (!this.isTCPlayer && video) {
        video.remove()
      }
    }

    _getTCPlayerConfig(playData: any, fileIdInfo: any) {
      let {token, antitheft_config: antitheftConfig} = fileIdInfo
      let {fileId, t, us, sign, exper} = antitheftConfig
      let config = {
        appID: antitheftConfig.appid,
        fileID: fileId,
        t, // 参考 Key 防盗链说明
        us, // 参考 Key 防盗链说明
        sign, // 参考 Key 防盗链说明
        exper, // 参考 试看功能说明
        plugins: {
          HLSToken: {
            token
          }
        }
      }
      return config
    }

    _getFileIdInfo(playData: any) {
      let {fileId, token} = playData
      return getPlayInfo({fileId, token}).catch(e => {
        this._videoError('playInfo 服务繁忙')
      })
    }

    _videoError(msg: string) {
      this.$message(msg)
    }

    pause() {
      this.qcVideo && this.qcVideo.pause()
    }
    error(e: any) {
      if (this.isTCPlayer) {
        let {code, source} = e.data
        this.errorText = `[${code}]: ${source.details}`
      } else {
        let map: any = {
          '2': '网络错误，请检查网络配置或者播放链接是否正确',
          '13': '直播已结束，请稍后再来',
          '2032': '请求视频失败，请检查网络',
          '2048': '请求m3u8文件失败，可能是网络错误或者跨域问题'
        }
        let {code, reason} = e.detail
        let msg = map[code] || reason
        this.errorText = `[${code}]: ${msg}`
      }
    }
    reTry() {
      this.errorText = ''
      if (this.isTCPlayer) {
        this.reloadPlayer(this.playData)
      } else {
        this.destroyPlayer()
        setTimeout(() => {
          this.initTcPlayer(this.playData)
        }, 1000)
      }
    }

    changeSpeed(times: number) {
      if (!this.qcVideo) {
        return
      }
      if (this.isTCPlayer) {
        this.qcVideo.playbackRate(times)
      } else {
        let el = this.qcVideo.video.el
        let defaultValue = el.defaultPlaybackRate
        let n = defaultValue * times
        el.playbackRate = n
      }
    }
}
